<template>
  <div class="md:flex flex-col md:flex-row md:min-h-screen w-full">
    <Sidebar />
<!--    <div class="bg-red-500 sm:bg-blue-500 md:bg-green-500 lg:bg-yellow-500 xl:bg-purple-500">-->
<!--      n-->
<!--    </div>-->
    <router-view :key="$route.path"/>
  </div>
</template>

<script>
import {appItems} from './menu-items';
import Sidebar from '@/components/Sidebar';

export default {
  components: {
    Sidebar,
  },
  data() {
    return {
      items: appItems,
    };
  },
};
</script>
