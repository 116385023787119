<template>
  <div
    v-click-outside="handleClickOutSide"
    class="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0"
  >
    <div class="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
      <a
        href="#"
        class="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
        >{{ name ?? 'WHFS' }}</a
      >
      <button
        class="rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline"
        @click="open = !open"
      >
        <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
          <path
            v-show="!open"
            fill-rule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clip-rule="evenodd"
          ></path>
          <path
            v-show="open"
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
    <nav
      :class="{block: dropdown, hidden: !dropdown}"
      class="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto"
    >
      <div v-click-outside="handleClickOutDropdown" class="relative">
        <button
          @click="dropdown = !dropdown"
          class="flex flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:block hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
        >
          <span class="flex min-w-0 items-center justify-between space-x-3">
            <span class="flex-1 flex flex-col min-w-0">
              <span class="text-gray-900 text-sm font-medium truncate">{{ userName[0] }}</span>
              <span class="text-gray-500 text-sm truncate">{{ userName[1] }}</span>
            </span>
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              :class="{'rotate-180': dropdown, 'rotate-0': !dropdown}"
              class="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
        </button>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <div
            v-show="dropdown"
            class="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg"
          >
            <div class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
              <amplify-sign-out button-text="Logout"></amplify-sign-out>
            </div>
          </div>
        </transition>
      </div>

      <RouterLink
        :class="[
          getActive(item.href)
            ? 'text-gray-500 bg-gray-200'
            : 'text-gray-400 group-hover:text-gray-500',
        ]"
        v-for="item in navigation"
        :key="item.name"
        :name="item.href"
      >
        <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
        {{ item.name }}
      </RouterLink>
    </nav>
  </div>
</template>

<script>
import {HomeIcon, DocumentReportIcon, MapIcon, UserIcon} from '@heroicons/vue/outline';
import {onAuthUIStateChange} from '@aws-amplify/ui-components';
import env from '@/config/env';
import {mapGetters, mapMutations} from 'vuex';
import RouterLink from '@/components/RouterLink';

export default {
  components: {
    RouterLink,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      group: 'app/getGroup',
    }),
    name() {
      return env.name;
    },
    userName() {
      return this.user && this.user.email ? this.user.email.split('@') : [];
    },
    getCurrentRoute() {
      return this.$route.name;
    },
    navigation() {
      let items = [
        {name: 'Home', href: 'home', icon: HomeIcon, current: false},
        {name: 'Maps', href: 'maps', icon: MapIcon, current: false},
        {name: 'Surveys', href: 'surveys', icon: DocumentReportIcon, current: false},
      ];
      if (this.userGroup && this.userGroup.includes('Admin')) {
        items.push({name: 'Users', href: 'users', icon: UserIcon, current: false});
      }
      return items;
    },
  },
  data() {
    return {
      userGroup: null,
      open: false,
      dropdown: false,
    };
  },
  methods: {
    ...mapMutations({
      logout: 'auth/LOGOUT',
    }),
    getActive(route) {
      return this.getCurrentRoute.includes(route);
    },
    handleClickOutSide() {
      this.open = false;
    },
    handleClickOutDropdown() {
      this.dropdown = false;
    },
  },
  async created() {
    this.userGroup = await this.group;
    this.unsubscribeAuth = onAuthUIStateChange(async () => {
      this.logout();
      this.$router.push({name: 'auth.login'});
    });
  },
};
</script>
