<template>
  <router-link
    :exact="exact"
    :to="{name, ...params}"
    :active-class="activeClass"
    :class="[
      current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
      'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
    ]"
    :aria-current="current ? 'page' : undefined"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    exact: Boolean,
    name: String,
    params: Object,
    activeClass: String,
    current: Boolean,
  },
};
</script>

<style scoped>
.text-white {
  color: white !important;
}
</style>
